import React from "react";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

export const defaultOptions = { threshold: 0.1, triggerOnce: true, delay: 50 };
export const stagger = 50; // 50ms
export const staggerStyle = (value = 0) => {
  return { transitionDelay: value * stagger + "ms" };
};

/**
 * Wrapper component for animations.
 * Simplifies the use so that one doesn't need to write out the hook or class changes every time.
 */
export const InView = ({ as: Tag = "div", className, options, children, ...props }) => {
  const { ref, inView } = useInView({ ...defaultOptions, ...options });

  return (
    <Tag ref={ref} className={clsx(className, "a-init", { "a-in-view": inView })} {...props}>
      {children}
    </Tag>
  );
};
