import React from "react";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import clsx from "clsx";

import "./Nav.scss";

const Nav = ({ burgerIsOpen, white }) => {
  const { t } = useTranslation();
  return (
    <menu className={clsx("nav", { "nav--burger-is-open": burgerIsOpen, "nav--white": white })}>
      <li className="nav__item">
        <Link className="nav-link u-hover-from-left" to={"/about/"}>
          {t("About")}
        </Link>
      </li>
      <li className="nav__item">
        <Link className="nav-link u-hover-from-left" to={"/projects/"}>
          {t("Projects")}
        </Link>
      </li>
      <li className="nav__item">
        <a
          className="nav-link u-hover-from-left"
          href={"https://nodes.web3.foundation"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Decentralized Nodes")}
        </a>
      </li>
      <li className="nav__item">
        <a
          className="nav-link u-hover-from-left"
          href={"https://education.web3.foundation"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Education")}
        </a>
      </li>
      <li className="nav__item">
        <a
          className="nav-link u-hover-from-left"
          href={"https://grants.web3.foundation"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Grants")}
        </a>
      </li>
      <li className="nav__item">
        <a
          className="nav-link u-hover-from-left"
          href={"https://research.web3.foundation"}
          target="_blank"
          rel="noreferrer"
        >
          {t("Research")}
        </a>
      </li>
      {/* <li className="nav__item">
        <Link className="nav-link u-hover-from-left" to={"/press/"}>
          {t("Press")}
        </Link>
      </li> */}
      <li className="nav__item">
        <Link className="nav-link u-hover-from-left" to={"https://web3.bamboohr.com/jobs/"}>
          {t("Careers")}
        </Link>
      </li>
      <li className="nav__item">
        <Link className="nav-link u-hover-from-left" to={"https://medium.com/web3foundation/"}>
          {t("Blog")}
        </Link>
      </li>
    </menu>
  );
};

export default Nav;
