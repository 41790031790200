import React, { forwardRef } from "react";
import clsx from "clsx";

import "./Container.scss";

const Container = (
  { Type = "section", children, className, innerClass, withBackground, noOverflow = true, ...props },
  ref
) => {
  return (
    <Type
      ref={ref}
      className={clsx("container", className, {
        "container--with-background": withBackground,
        "container--no-overflow": noOverflow,
      })}
      {...props}
    >
      <div className={clsx("container__inner", innerClass)}>{children}</div>
    </Type>
  );
};

export default forwardRef(Container);
