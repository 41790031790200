import React from "react";
import clsx from "clsx";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";

import "./FooterNav.scss";

const FooterNav = ({ className }) => {
  const { t } = useTranslation();

  return (
    <nav className={clsx("footer-nav", className)}>
      <menu className="footer-nav__col">
        <li className="footer-nav__item">
          <Link className="footer-nav-link u-hover-from-left" to={"/about/"}>
            {t("About")}
          </Link>
        </li>
        <li className="footer-nav__item">
          <Link className="footer-nav-link u-hover-from-left" to={"/projects/"}>
            {t("Projects")}
          </Link>
        </li>
        <li className="footer-nav__item">
          <a
            className="footer-nav-link u-hover-from-left"
            href={"https://nodes.web3.foundation"}
            target="_blank"
            rel="noreferrer"
          >
            {t("Decentralized")}
            <br />
            {t("Nodes")}
          </a>
        </li>
        <li className="footer-nav__item">
          <a
            className="footer-nav-link u-hover-from-left"
            href={"https://education.web3.foundation"}
            target="_blank"
            rel="noreferrer"
          >
            {t("Education")}
          </a>
        </li>
        <li className="footer-nav__item">
          <a
            className="footer-nav-link u-hover-from-left"
            href={"https://grants.web3.foundation"}
            target="_blank"
            rel="noreferrer"
          >
            {t("Grants")}
          </a>
        </li>
        <li className="footer-nav__item">
          <a
            className="footer-nav-link u-hover-from-left"
            href={"https://spec.polkadot.network"}
            target="_blank"
            rel="noreferrer"
          >
            {t("Spec")}
          </a>
        </li>
        <li className="footer-nav__item">
          <a
            className="footer-nav-link u-hover-from-left"
            href={"https://research.web3.foundation"}
            target="_blank"
            rel="noreferrer"
          >
            {t("Research")}
          </a>
        </li>
      </menu>
      <menu className="footer-nav__col">
        {/* <li className="footer-nav__item">
          <Link className="footer-nav-link u-hover-from-left" to={"/press/"}>
            {t("Press")}
          </Link>
        </li> */}
        <li className="footer-nav__item">
          <Link className="footer-nav-link u-hover-from-left" to={"https://web3.bamboohr.com/jobs/"}>
            {t("Careers")}
          </Link>
        </li>
        <li className="footer-nav__item">
          <Link className="footer-nav-link u-hover-from-left" to={"https://medium.com/web3foundation/"}>
            {t("Blog")}
          </Link>
        </li>
      </menu>
    </nav>
  );
};

export default FooterNav;
