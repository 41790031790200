import { useEffect } from "react";

/**
 * Creates a scroll lock so that fullscreen elements or modals disable scrolling.
 * Additionally, adds padding in the size (width) of the scrollbar to refs
 * so that content doesn't change its position.
 *
 * @param isActive The trigger to initiate the scroll lock
 * @param refs References to elements
 */
const useScrollLock = ({ isActive, refs }) => {
  useEffect(() => {
    if (!document || !isActive) return;

    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    const overflow = document.body.style.overflow;

    document.body.style.overflow = "hidden";

    refs.forEach((ref) => {
      if (ref.current) {
        ref.current.style.paddingRight = `${scrollBarWidth}px`;
      }
    });

    return () => {
      document.body.style.overflow = overflow;

      refs.forEach((ref) => {
        if (ref.current) {
          ref.current.style.paddingRight = null;
        }
      });
    };
  }, [isActive]);
};

export default useScrollLock;
