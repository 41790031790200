import React from "react";
import clsx from "clsx";

import "./Burger.scss";

const Burger = ({ id = "burger", isOpen, setIsOpen, white = false }) => {
  return (
    <div className={clsx("burger", { "burger--is-open": isOpen, "burger--is-white": white })}>
      <input id={id} type="checkbox" onClick={() => setIsOpen(!isOpen)} aria-label="Menu" aria-controls="navigation" />
      <label htmlFor={id}>
        <div />
        <div />
        <div />
      </label>
    </div>
  );
};

export default Burger;
