import React, { useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import clsx from "clsx";
import Container from "layouts/Container";
import FooterNav from "components/site/Footer/FooterNav";
import SocialNav from "components/site/Footer/SocialNav";
import FooterBottom from "components/site/Footer/FooterBottom";

import Logo from "/src/images/svgs/logo-icon.svg";
import SocialTwitter from "/src/images/svgs/social-twitter.svg";
import SocialYoutube from "/src/images/svgs/social-youtube.svg";
import SocialGithub from "/src/images/svgs/social-github.svg";
import SocialMedium from "/src/images/svgs/social-medium.svg";

import "./Footer.scss";

const socialItems = [
  { title: "Twitter", icon: SocialTwitter, url: "https://twitter.com/web3foundation" },
  { title: "Youtube", icon: SocialYoutube, url: "https://www.youtube.com/channel/UClnw_bcNg4CAzF772qEtq4g" },
  { title: "GitHub", icon: SocialGithub, url: "https://github.com/w3f" },
  { title: "Medium", icon: SocialMedium, url: "https://medium.com/web3foundation" },
];

const Footer = ({ image, isHomepage }) => {
  const img = getImage(image);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const simpleParallax = require("simple-parallax-js");

      const image = document.getElementsByClassName("footer__background-image");

      new simpleParallax(image, {
        scale: 1.6,
        delay: 1,
      });
    }
  }, []);

  return (
    <footer className={clsx("footer", { "footer--is-homepage": isHomepage })}>
      <div className="footer__background">
        <GatsbyImage className="footer__background-image" image={img} alt="" objectPosition="19% 10%" />
      </div>
      <Container className="footer__foreground" innerClass="footer__foreground-inner">
        <div className="footer__logo-wrapper">
          <Link to="/">
            <Logo className="footer__logo" />
          </Link>
        </div>
        <FooterNav className="footer__nav" />
        <SocialNav className="footer__social" items={socialItems} />
        <FooterBottom className="footer_bottom" isHomepage={isHomepage} />
      </Container>
    </footer>
  );
};

export default Footer;
