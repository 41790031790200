import React, { useRef, useState } from "react";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import clsx from "clsx";
import useScrollLock from "hooks/use-scroll-lock";
import Logo from "/src/images/svgs/logo.svg";
import Container from "layouts/Container";
import Nav from "components/site/Nav";
import Burger from "elements/Burger";

import "./Header.scss";

const Header = ({ mainRef, transparent = false, fixed = false }) => {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false);
  const headerRef = useRef();

  useScrollLock({ refs: [mainRef, headerRef], isActive: burgerIsOpen && !fixed });

  return (
    <>
      <div className={clsx("header__spacer", { "header__spacer--burger-is-open": burgerIsOpen && !fixed })} />
      <Container
        ref={headerRef}
        className={clsx("header", {
          "header--burger-is-open": burgerIsOpen,
          "header--fixed": fixed,
          "header--transparent": transparent,
        })}
        innerClass="header__inner"
        type="header"
        role="banner"
      >
        <Link className="header__logo" to="/">
          <Logo />
        </Link>
        <Burger isOpen={burgerIsOpen} setIsOpen={setBurgerIsOpen} white={fixed} />
        <Nav burgerIsOpen={burgerIsOpen} white={fixed} />
      </Container>
    </>
  );
};

export default Header;
