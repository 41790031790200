import React from "react";
import clsx from "clsx";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";
import { InView, staggerStyle } from "utils/animation";
import LogoFullPolkadot from "/src/images/svgs/logo-full-polkadot.svg";
import LogoKusama from "/src/images/svgs/logo-kusama.svg";
import LogoWeb3Summit from "/src/images/svgs/logo-web3-summit.svg";

import "./FooterBottom.scss";

const FooterBottom = ({ className, isHomepage }) => {
  return (
    <InView options={{ threshold: 0, triggerOnce: true, skip: !isHomepage }}>
      <div className={clsx("footer-bottom", className, { "a-fade-up-and-in": isHomepage })} style={staggerStyle(4)}>
        <div className="footer-bottom__icons">
          <Link className="footer-bottom__item" to="https://polkadot.network/">
            <LogoFullPolkadot className="footer-bottom__icon footer-bottom__icon--polkadot" />
          </Link>
          <Link className="footer-bottom__item" to="https://kusama.network/">
            <LogoKusama className="footer-bottom__icon footer-bottom__icon--kusama" />
          </Link>
          <Link className="footer-bottom__item" to="https://web3summit.com/">
            <LogoWeb3Summit className="footer-bottom__icon footer-bottom__icon--web3summit" />
          </Link>
        </div>
        <ul className="footer-bottom__legal">
          <li className="footer-bottom__item t-sm">
            <Link to="/security-report/">Security Report</Link>
          </li>
          <li className="footer-bottom__item t-sm">
            <Link to="/legal-disclosures/">Legal Disclosures</Link>
          </li>
          <li className="footer-bottom__item t-sm">
            <Link to="/privacy-and-cookies/">Privacy and Cookies</Link>
          </li>
          <li className="footer-bottom__item t-sm">
            <p> © {new Date().getFullYear()} Web3 Foundation, All Rights Reserved.</p>
          </li>
        </ul>
      </div>
    </InView>
  );
};

export default FooterBottom;
