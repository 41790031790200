import React from "react";
import clsx from "clsx";
import { Link } from "@w3f/gatsby-theme-w3f/src/components/default/Link";

import "./SocialNav.scss";

const SocialNav = ({ className, items }) => {
  return (
    <ul className={clsx("social-nav", className)}>
      {items.map(({ title, icon: Icon, url, noPadding }, index) => {
        return (
          <li key={index} className="social-nav__item u-hover-grow">
            <Link
              className={clsx("social-nav__link", { "social-nav__link--no-padding": noPadding })}
              to={url}
              alt={title}
            >
              <Icon className="social-nav__icon" />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialNav;
