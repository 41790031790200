import React, { useRef } from "react";
import clsx from "clsx";
import Header from "./Header";
import Footer from "components/site/Footer";

import "./Layout.scss";

export default function Layout({ className, headerOptions, footerImage, children, isHomepage }) {
  const mainRef = useRef();

  return (
    <div className={clsx("layout", className)}>
      <Header mainRef={mainRef} transparent={isHomepage} {...headerOptions} />

      <main ref={mainRef} className="layout__main">
        {children}
      </main>

      <Footer image={footerImage} isHomepage={isHomepage} />
    </div>
  );
}
